import { IArkeClient } from "../Client/IArkeClient";
import {
  HostResponseV1,
  PaginatedItemsResponse,
  HostResponseWithIdentityResponseV1
} from "../Entities";
import { WebErrorResponse } from "simplerestclients";

interface IHostResourceGroup {
  list(
    siteId: string,
    page: number,
    itemsPerPage: number,
    filter?: string
  ): Promise<PaginatedItemsResponse<HostResponseV1>>;
  find(
    siteId: string,
    hostId: string
  ): Promise<HostResponseWithIdentityResponseV1 | null>;

  remove(siteId: string, hostId: string): Promise<void>;
  create(siteId: string, name: string, phoneNumber: string): Promise<string>;

  messageIdentity(siteId: string, hostId: string): Promise<void>;
}

class HostResourceGroup implements IHostResourceGroup {
  public async create(
    siteId: string,
    name: string,
    phoneNumber: string
  ): Promise<string> {
    const result = await this.Client.performPost<{ hostId: string }>(
      `/v1/sites/${siteId}/hosts`,
      {
        name: name,
        phoneNumber: phoneNumber
      }
    );
    return result.hostId;
  }
  public remove(siteId: string, hostId: string): Promise<void> {
    return this.Client.performDelete(`/v1/sites/${siteId}/hosts/${hostId}`);
  }
  private Client: IArkeClient;
  constructor(client: IArkeClient) {
    this.Client = client;
  }

  public list(
    siteId: string,
    page: number,
    itemsPerPage: number,
    filter?: string
  ): Promise<PaginatedItemsResponse<HostResponseV1>> {
    let url =
      "/v1/sites/" +
      siteId +
      "/hosts?page=" +
      page +
      "&itemsPerPage=" +
      itemsPerPage;
    if (filter) {
      url += "&filter=" + filter;
    }

    return this.Client.performGet<PaginatedItemsResponse<HostResponseV1>>(url);
  }

  public async find(
    siteId: string,
    hostId: string
  ): Promise<HostResponseWithIdentityResponseV1 | null> {
    return this.Client.performNullableGet<HostResponseWithIdentityResponseV1>(
      `/v1/sites/${siteId}/hosts/${hostId}`
    );
  }

  public messageIdentity(siteId: string, hostId: string): Promise<void> {
    return this.Client.performPost(
      `/v1/sites/${siteId}/hosts/${hostId}/message-identity`,
      {}
    );
  }
}

export { HostResourceGroup, IHostResourceGroup };
