import {
  ISiteResourceGroup,
  SiteResourceGroup,
} from "./ResourceGroups/Sites/Sites";
import { IHostResourceGroup, HostResourceGroup } from "./ResourceGroups/Hosts";
import { ICallResourceGroup, CallResourceGroup } from "./ResourceGroups/Calls";
import { IAppResourceGroup, AppResourceGroup } from "./ResourceGroups/Apps";
import {
  ISupportRequestResourceGroup,
  SupportRequestResourceGroup,
} from "./ResourceGroups/SupportRequests";
import ArkeClient from "./Client/ArkeClient";
import { VisitResourceGroup } from "./ResourceGroups/Visits/Visits";
import { IVisitResourceGroup } from "./ResourceGroups/Visits/IVisitResourceGroup";
import {
  IDeviceResourceGroup,
  DeviceResourceGroup,
} from "./ResourceGroups/Devices";
import {
  SystemResourceGroup,
  ISystemResourceGroup,
} from "./ResourceGroups/System";
import {
  IClientResourceGroup,
  ClientResourceGroup,
} from "./ResourceGroups/Clients";
import {
  AccountResourceGroup,
  IAccountResourceGroup,
} from "./ResourceGroups/Accounts";
import {
  IVisitDocumentResourceGroup,
  VisitDocumentResourceGroup,
} from "./ResourceGroups/VisitDocuments";
import { IUserResourceGroup, UserResourceGroup } from "./ResourceGroups/Users";
import { IArkeClient } from "./Client/IArkeClient";
import {
  ConversationResourceGroup,
  IConversationResourceGroup,
} from "./ResourceGroups/Conversations/Conversations";
import {
  IVisitorVisitsResourceGroup,
  VisitorVisitsResourceGroup,
} from "./ResourceGroups/Visitor/VisitorVisits";

export interface IArke extends IArkeClient {
  accounts: IAccountResourceGroup;
  sites: ISiteResourceGroup;
  visits: IVisitResourceGroup;
  hosts: IHostResourceGroup;
  calls: ICallResourceGroup;
  supportRequests: ISupportRequestResourceGroup;
  devices: IDeviceResourceGroup;
  apps: IAppResourceGroup;
  system: ISystemResourceGroup;
  clients: IClientResourceGroup;
  visitDocuments: IVisitDocumentResourceGroup;
  users: IUserResourceGroup;
  conversation: IConversationResourceGroup;
  visitorVisits: IVisitorVisitsResourceGroup;
}

export class Arke extends ArkeClient implements IArke {
  public system: ISystemResourceGroup;
  public sites: ISiteResourceGroup;
  public visits: IVisitResourceGroup;
  public hosts: IHostResourceGroup;
  public calls: ICallResourceGroup;
  public supportRequests: ISupportRequestResourceGroup;
  public devices: IDeviceResourceGroup;
  public apps: IAppResourceGroup;
  public clients: IClientResourceGroup;
  public users: IUserResourceGroup;
  public visitDocuments: IVisitDocumentResourceGroup;
  public accounts: IAccountResourceGroup;
  public conversation: IConversationResourceGroup;
  public visitorVisits: IVisitorVisitsResourceGroup;
  constructor(baseUrl: string, refreshToken: () => Promise<string | null>) {
    super(baseUrl, refreshToken);
    this.sites = new SiteResourceGroup(this);
    this.visits = new VisitResourceGroup(this);
    this.hosts = new HostResourceGroup(this);
    this.calls = new CallResourceGroup(this);
    this.supportRequests = new SupportRequestResourceGroup(this);
    this.devices = new DeviceResourceGroup(this);
    this.apps = new AppResourceGroup(this);
    this.users = new UserResourceGroup(this);
    this.system = new SystemResourceGroup(this);
    this.clients = new ClientResourceGroup(this);
    this.accounts = new AccountResourceGroup(this);
    this.visitDocuments = new VisitDocumentResourceGroup(this);
    this.conversation = new ConversationResourceGroup(this);
    this.visitorVisits = new VisitorVisitsResourceGroup(this);
  }
}
