import { Net2TelephoneField, PinMode, PinLength } from "../../Helpers";
import { VisitLimitMode } from "./SiteSettingRequests";
export interface SiteSettingsResponseV1 {
  name: string;
  retentionDays: number | null;
  countryCode: string;
  cultureId: string;
  timeZoneId: string;
  emergencyPhoneNumber: string | null;
}
export interface SiteMessageTemplatesResponseV1 {
  pinMessage: string;
  welcomeMessage: string;
  welcomeMessageEnabled: boolean;
}
export interface SiteNotificationSettingsResponseV1 {
  sendWelcomeMessage: boolean;
  statusEmailList: string;
  pushStatusNotification: number;
  sendHostCalendarInvite: boolean;
}
export interface SiteClientResponseV1 {
  version: string;
  thumbprint: string | null;
  endpoint: string;
}
export interface SiteNet2HostProvisioningSettingsResponseV1 {
  telephoneField: Net2TelephoneField;
  automaticallySyncNewDepartments: boolean;
  departments: SiteNet2DepartmentResponseV1[];
}
export interface SiteNet2DepartmentResponseV1 {
  synchronised: boolean;
  departmentId: number;
  name: string;
}
export interface GroupVisitLimitOverrides {
  groupId: string;
  dailyLimit: number | null;
}

export interface DailyVisitLimitSettingsResponseV1 {
  visitLimitMode: VisitLimitMode;
  allowDailyVisitLimitOverrideByStaff: boolean;
  globalDailyVisitLimit: number | null;
  groupVisitLimitOverrides: GroupVisitLimitOverrides[];
}

export interface SiteAccessSettingsResponseV1 {
  dailyLimitSettingsResponseV1: DailyVisitLimitSettingsResponseV1;
  pinMode: PinMode;
  pinLength: PinLength;
  pinExpirationHours: number;
  pinExpirationDays: number;
  useHostAccessLevel: boolean;
  net2InFilter: number[];
  net2OutFilter: number[];
  scanning: boolean;
  unregisteredVisitor: boolean;
  allowDailyVisitLimitOverrideByStaff: boolean;
}

export interface SiteNet2DoorV1 {
  siteId: string;
  serialNumber: number;
  name: string;
}

export enum AccessCodeGenerationModes {
  PIN = "PIN",
  QR = "QR",
}
