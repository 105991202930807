import { IArkeClient } from "../../Client/IArkeClient";
import { ManageableRoleResponseV1 } from "./MangeableRoleResponseV1";
import { AddUserRequestV1 } from "./AddUserRequestV1";
import { UserRolesResponseV1 } from "./UserRolesResponseV1";
import { PaginatedItemsResponse } from "../../Entities";
import { UserRoles } from "./UserRoles";

interface IUserResourceGroup {
  add(siteId: string, request: AddUserRequestV1): Promise<void>;
  find(siteId: string, email: string): Promise<UserRolesResponseV1>;
  removeRole(siteId: string, email: string, role: UserRoles): Promise<void>;
  addRole(siteId: string, email: string, role: UserRoles): Promise<void>;
  list(
    siteId: string,
    page: number,
    itemsPerPage: number
  ): Promise<PaginatedItemsResponse<string>>;
  listManageableRoles(siteId: string): Promise<ManageableRoleResponseV1[]>;
}

class UserResourceGroup implements IUserResourceGroup {
  private Client: IArkeClient;
  constructor(client: IArkeClient) {
    this.Client = client;
  }
  list(
    siteId: string,
    page: number,
    itemsPerPage: number
  ): Promise<PaginatedItemsResponse<string>> {
    return this.Client.performGet<PaginatedItemsResponse<string>>(
      `/v1/sites/${siteId}/users?itemsPerPage=${itemsPerPage}&page=${page}`
    );
  }
  public listManageableRoles(
    siteId: string
  ): Promise<ManageableRoleResponseV1[]> {
    return this.Client.performGet<ManageableRoleResponseV1[]>(
      `/v1/sites/${siteId}/users/roles/manageable`
    );
  }
  public add(siteId: string, request: AddUserRequestV1): Promise<void> {
    return this.Client.performPost(`/v1/sites/${siteId}/users`, request);
  }
  public removeRole(
    siteId: string,
    email: string,
    role: UserRoles
  ): Promise<void> {
    return this.Client.performDelete(
      `/v1/sites/${siteId}/users/${email}/roles/${role}`
    );
  }
  public addRole(
    siteId: string,
    email: string,
    role: UserRoles
  ): Promise<void> {
    return this.Client.performPost(
      `/v1/sites/${siteId}/users/${email}/roles/${role}`,
      {}
    );
  }
  public find(siteId: string, email: string): Promise<UserRolesResponseV1> {
    return this.Client.performGet<UserRolesResponseV1>(
      `/v1/sites/${siteId}/users/${email}`
    );
  }
}

export { UserResourceGroup, IUserResourceGroup };
