import { Net2TelephoneField, PinMode, PinLength } from "../../Helpers";
import { GroupVisitLimitOverrides } from "./SiteSettingsResponses";

export enum VisitLimitMode {
  daily,
  simultaneous,
}

export declare class UpdateSiteSettingsRequestV1 {
  name: string;
  timeZoneId: string;
  cultureId: string;
  retentionDays: number | null;
  emergencyPhoneNumber: string | null;
}
export declare class UpdateSiteClientRequestV1 {
  subscriptionId: string;
  thumbprintCharacters: string;
}
export declare class UpdateSiteMessageTemplatesRequestV1 {
  welcomeMessage: string;
  pinMessage: string;
}
export declare class UpdateSiteNotificationSettingsRequestV1 {
  sendWelcomeMessage: boolean;
  statusEmailList: string;
  pushStatusNotification: number;
  sendHostCalendarInvite: boolean;
}
export declare class AddSiteRequestV1 {
  name: string;
  countryCode: string;
  timeZoneId: string;
  cultureId: string;
  net2PhoneField: Net2TelephoneField;
}
export declare class UpdateSiteNet2HostProvisioningSettingsRequestV1 {
  telephoneField: Net2TelephoneField;
  automaticallySyncNewDepartments: boolean;
  departments: UpdateSiteNet2DepartmentRequestV1[];
}
export declare class UpdateSiteNet2DepartmentRequestV1 {
  synchronised: boolean;
  departmentId: number;
}
export declare class DailyVisitLimitSettingsRequestV1 {
  visitLimitMode: VisitLimitMode;
  allowDailyVisitLimitOverrideByStaff: boolean;
  globalDailyVisitLimit: number | null;
  groupVisitLimitOverrides: { [k: string]: any };
}

export declare class UpdateSiteAccessSettingsRequestV1 {
  dailyLimitSettings: DailyVisitLimitSettingsRequestV1;
  pinMode: PinMode;
  pinLength: PinLength;
  pinExpirationHours: number;
  pinExpirationDays: number;
  useHostAccessLevel: boolean;
  net2InFilter: number[];
  net2OutFilter: number[];
  scanning: boolean;
  unregisteredVisitor: boolean;
}
