export enum SitePermissions {
  "accountsitescreate" = "accountsites:create",
  "accountsitesread" = "accountsites:read",
  "accountsitesupdate" = "accountsites:update",
  "broadcastcreate" = "broadcast:create",
  "broadcastread" = "broadcast:read",
  "conversationscreate" = "conversations:create",
  "conversationsread" = "conversations:read",
  "conversationsupdate" = "conversations:update",
  "hostscreate" = "hosts:create",
  "hostsdelete" = "hosts:delete",
  "hostsread" = "hosts:read",
  "hostsupdate" = "hosts:update",
  "siteaccesssettingsread" = "siteaccesssettings:read",
  "siteaccesssettingsupdate" = "siteaccesssettings:update",
  "sitehostprovisioningsettingscreate" = "sitehostprovisioningsettings:create",
  "sitehostprovisioningsettingsread" = "sitehostprovisioningsettings:read",
  "sitehostprovisioningsettingsupdate" = "sitehostprovisioningsettings:update",
  "sitemessagetemplatesread" = "sitemessagetemplates:read",
  "sitemessagetemplatesupdate" = "sitemessagetemplates:update",
  "sitemessagingread" = "sitemessaging:read",
  "sitenotificationsettingsread" = "sitenotificationsettings:read",
  "sitenotificationsettingsupdate" = "sitenotificationsettings:update",
  "sitesettingsdelete" = "sitesettings:delete",
  "sitesettingsread" = "sitesettings:read",
  "sitesettingsupdate" = "sitesettings:update",
  "sitestatisticsread" = "sitestatistics:read",
  "sitesyncscreate" = "sitesyncs:create",
  "siteusersettingscreate" = "siteusersettings:create",
  "siteusersettingsdelete" = "siteusersettings:delete",
  "siteusersettingsread" = "siteusersettings:read",
  "siteusersettingsupdate" = "siteusersettings:update",
  "unregisteredvisitorcreate" = "unregisteredvisitor:create",
  "unregisteredvisitorread" = "unregisteredvisitor:read",
  "visitdocumentscreate" = "visitdocuments:create",
  "visitdocumentsdelete" = "visitdocuments:delete",
  "visitdocumentsread" = "visitdocuments:read",
  "visitexportscreate" = "visitexports:create",
  "visitpinsread" = "visitpins:read",
  "visitscreate" = "visits:create",
  "visitscreateas" = "visits:createas",
  "visitsread" = "visits:read",
  "visitsreadall" = "visits:readall",
  "visitsupdate" = "visits:update",
  "visitscancelcreate" = "visitscancel:create",
  "visitscancelcreateas" = "visitscancel:createas",
  "visitsdailyoverridecreate" = "visitsdailyoverride:create",
  "watchlistcreate" = "watchlist:create",
  "watchlistread" = "watchlist:read",
  "watchlistupdate" = "watchlist:update",
  "watchlistdelete" = "watchlist:delete",
  "additionalfieldsreate" = "additionalfields:create",
  "additionalfieldsread" = "additionalfields:read",
  "additionalfieldsupdate" = "additionalfields:update",
  "additionalfieldsdelete" = "additionalfields:delete",
}
export enum AccountPermissions {
  "accountsread" = "accounts:read",
  "accountsupdate" = "accounts:update",
  "accountsitescreate" = "accountsites:create",
  "accountsitesread" = "accountsites:read",
  "accountsitesupdate" = "accountsites:update",
}
