import { IArkeClient } from "../Client/IArkeClient";
import { CallResponseV1, UnregisteredVisitorCallResponseV1 } from "../Entities";

interface ICallResourceGroup {
  findUnregisteredVisitorCall(
    siteId: string,
    visitId: string,
    callId: string
  ): Promise<UnregisteredVisitorCallResponseV1 | null>;
  newUnregisteredVisitorCall(
    siteId: string,
    visitId: string
  ): Promise<CallResponseV1>;
}

class CallResourceGroup implements ICallResourceGroup {
  private Client: IArkeClient;
  constructor(client: IArkeClient) {
    this.Client = client;
  }
  public async findUnregisteredVisitorCall(
    siteId: string,
    visitId: string,
    callId: string
  ): Promise<UnregisteredVisitorCallResponseV1 | null> {
    return this.Client.performNullableGet<UnregisteredVisitorCallResponseV1>(
      `/v1/sites/${siteId}/visits/${visitId}/calls/${callId}`
    );
  }

  public async newUnregisteredVisitorCall(
    siteId: string,
    visitId: string
  ): Promise<CallResponseV1> {
    const response = await this.Client.performPost<CallResponseV1>(
      `/v1/sites/${siteId}/visits/${visitId}/calls`,
      {}
    );
    return response;
  }
}

export { ICallResourceGroup, CallResourceGroup };
