export enum WatchlistType {
  PhoneNumber = "PhoneNumber",
  LicensePlate = "LicensePlate",
}

export class WatchlistRequestV1 {
  public pattern!: string;
  public type!: WatchlistType | "All";
  public description!: string;
  public blockVisits?: boolean;
  public notifyStaff?: boolean;
}

export class UpdateWatchlistRequestV1 {
  public watchlistId!: string;
  public pattern!: string;
  public type!: WatchlistType | "All";
  public description!: string;
  public blockVisits?: boolean;
  public notifyStaff?: boolean;
}

export interface WatchlistResponseV1 {
  watchlistId: string;
  pattern: string;
  type: WatchlistType;
  description: string;
  dateAdded: string;
  blockVisits?: boolean;
  notifyStaff?: boolean;
}

export interface AddToWatchlistResponse {
  watchlistId: string;
}

export interface UpdateWatchlistResponse {
  watchlistId: string;
}
