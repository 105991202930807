import {
  GenericRestClient,
  ApiCallOptions,
  SimpleWebRequestBase,
  WebErrorResponse,
  ErrorHandlingType,
  WebResponse
} from "simplerestclients";
import { IArkeClient } from "./IArkeClient";
import { Mutex } from "../Helpers/Mutex";

export default class ArkeClient extends GenericRestClient
  implements IArkeClient {
  public baseUrl: string;
  public getAccessToken(): string | undefined {
    return this._accessToken;
  }
  private _accessToken: string | undefined;
  private mutex: Mutex = new Mutex();
  private _refreshToken: () => Promise<string | null>;
  private _additionalHeaders: { [header: string]: string };
  private _preRequestCheck?: () => Promise<void>;
  constructor(baseUrl: string, refreshToken: () => Promise<string | null>) {
    super(baseUrl);
    this.baseUrl = baseUrl;
    this._additionalHeaders = {};
    this._refreshToken = refreshToken;
    this._defaultOptions.customErrorHandler = this.handleErrors;
  }
  public setAdditionalHeader(key: string, value: string) {
    this._additionalHeaders[key] = value;
  }
  public removeAdditionalHeader(key: string) {
    delete this._additionalHeaders[key];
  }

  public attachPreRequestCheck(check: () => Promise<void>) {
    this._preRequestCheck = check;
  }

  public clearPreRequestCheck() {
    this._preRequestCheck = undefined;
  }

  public async refreshToken(): Promise<void> {
    const result = await this._refreshToken();
    if (result !== null) {
      this._accessToken = result;
    }
  }

  private async refreshTokenForRequest(webRequest: SimpleWebRequestBase) {
    console.debug("Refreshing token for request");
    const result = await this._refreshToken();
    if (result === null) {
      console.debug("Result is null, aboring request");
      webRequest.abort();
      return;
    } else {
      this._accessToken = result;
      webRequest.resumeRetrying();
    }
  }

  private handleErrors = (
    webRequest: SimpleWebRequestBase,
    errorResponse: WebErrorResponse
  ): ErrorHandlingType => {
    if (errorResponse.statusCode === 401) {
      this.refreshTokenForRequest(webRequest);
      return ErrorHandlingType.PauseUntilResumed;
    } else {
      return ErrorHandlingType.DoNotRetry;
    }
  };
  protected _getHeaders(options: ApiCallOptions): { [key: string]: string } {
    let headers = this._additionalHeaders;

    options.acceptType = "application/json";
    options.contentType = "application/json";
    headers["Authorization"] = "Bearer " + this._accessToken;

    return headers;
  }

  public setAccessToken(token: string): void {
    this._accessToken = token;
  }

  private async executeCheck() {
    if (this._preRequestCheck !== undefined) {
      this.mutex.dispatch(this._preRequestCheck);
    }
  }

  public async performNullableGet<T>(
    apiPath: string,
    options?: ApiCallOptions
  ): Promise<T | null> {
    try {
      await this.executeCheck();
      const response = await this.performApiGet<T>(apiPath, options);
      return response;
    } catch (e) {
      const error: WebErrorResponse = e;
      if (error.statusCode === 404) {
        return null;
      }
      throw e;
    }
  }

  public async performGet<T>(
    apiPath: string,
    options?: ApiCallOptions
  ): Promise<T> {
    await this.executeCheck();
    const response = await this.performApiGet<T>(apiPath, options);
    return response;
  }
  public async performGetDetailed<T>(
    apiPath: string,
    options?: ApiCallOptions
  ): Promise<WebResponse<T>> {
    await this.executeCheck();
    const response = await this.performGetDetailed<T>(apiPath, options);
    return response;
  }
  public async performPost<T>(
    apiPath: string,
    objToPost: any,
    options?: ApiCallOptions
  ): Promise<T> {
    await this.executeCheck();
    const response = await this.performApiPost<T>(apiPath, objToPost, options);
    return response;
  }
  public async performPostDetailed<T>(
    apiPath: string,
    objToPost: any,
    options?: ApiCallOptions
  ): Promise<WebResponse<T>> {
    await this.executeCheck();
    const response = await this.performApiPostDetailed<T>(
      apiPath,
      objToPost,
      options
    );
    return response;
  }
  public async performPatch<T>(
    apiPath: string,
    objToPatch: any,
    options?: ApiCallOptions
  ): Promise<T> {
    await this.executeCheck();
    const response = await this.performApiPatch<T>(
      apiPath,
      objToPatch,
      options
    );
    return response;
  }
  public async performPatchDetailed<T>(
    apiPath: string,
    objToPatch: any,
    options?: ApiCallOptions
  ): Promise<WebResponse<T>> {
    await this.executeCheck();
    const response = await this.performApiPatchDetailed<T>(
      apiPath,
      objToPatch,
      options
    );
    return response;
  }
  public async performPut<T>(
    apiPath: string,
    objToPut: any,
    options?: ApiCallOptions
  ): Promise<T> {
    await this.executeCheck();
    const response = await this.performApiPut<T>(apiPath, objToPut, options);
    return response;
  }
  public async performPutDetailed<T>(
    apiPath: string,
    objToPut: any,
    options?: ApiCallOptions
  ): Promise<WebResponse<T>> {
    await this.executeCheck();
    const response = await this.performApiPutDetailed<T>(
      apiPath,
      objToPut,
      options
    );
    return response;
  }
  public async performDelete<T>(
    apiPath: string,
    objToDelete?: any,
    options?: ApiCallOptions
  ): Promise<T> {
    await this.executeCheck();
    const response = await this.performApiDelete<T>(
      apiPath,
      objToDelete,
      options
    );
    return response;
  }
  public async performDeleteDetailed<T>(
    apiPath: string,
    objToDelete: any,
    options?: ApiCallOptions
  ): Promise<WebResponse<T>> {
    await this.executeCheck();
    const response = await this.performApiDeleteDetailed<T>(
      apiPath,
      objToDelete,
      options
    );
    return response;
  }
}
