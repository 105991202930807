﻿import { IArkeClient } from "../../Client/IArkeClient";
import {
  BroadcastMessageSettingsResponseV1,
  SendBroadcastMessageRequestV1,
  SendBroadcastMessageResponseV1,
  SendTestMessageRequestV1,
  SendTestMessageResponseV1,
  ConversationResponseV1,
  ConversationMessageResponseV1,
  SendConversationMessageRequestV1,
  CreateConversationResponseV1,
  CreateConversationRequestV1,
  BroadcastResponseV1,
  StatusUpdateRequest,
} from "./Entities";
import { PaginatedItemsResponse } from "../../Entities";

interface IConversationResourceGroup {
  sendBroadcast(
    siteId: string,
    request: SendBroadcastMessageRequestV1
  ): Promise<SendBroadcastMessageResponseV1>;

  sendBroadcastMessage(
    siteId: string,
    request: SendBroadcastMessageRequestV1
  ): Promise<SendBroadcastMessageResponseV1>;

  getBroadcasts(
    siteId: string,
    page?: number,
    itemsPerPage?: number,
    filter?: string
  ): Promise<PaginatedItemsResponse<BroadcastResponseV1>>;

  getBroadcastMessage(
    siteId: string,
    broadcastId: string
  ): Promise<BroadcastResponseV1>;

  getBroadcastSettings(
    siteId: string
  ): Promise<BroadcastMessageSettingsResponseV1>;

  sendTestMessage(
    siteId: string,
    request: SendTestMessageRequestV1
  ): Promise<SendTestMessageResponseV1>;

  getConversations(
    siteId: string,
    page: number,
    itemsPerPage: number,
    filter?: string,
    status?: string
  ): Promise<PaginatedItemsResponse<ConversationResponseV1>>;

  createConversation(
    siteId: string,
    request: CreateConversationRequestV1
  ): Promise<CreateConversationResponseV1>;

  createBroadcastReply(
    siteId: string,
    broadcastId: string,
    request: SendConversationMessageRequestV1
  ): Promise<CreateConversationResponseV1>;

  getConversation(
    siteId: string,
    conversationId: string
  ): Promise<ConversationResponseV1>;

  updateConversationStatus(
    siteId: string,
    conversationId: string,
    request: StatusUpdateRequest
  ): Promise<void>;

  getConversationMessages(
    siteId: string,
    conversationId: string,
    page: number,
    numberOfMessages: number
  ): Promise<PaginatedItemsResponse<ConversationMessageResponseV1>>;

  sendConversationMessage(
    siteId: string,
    conversationId: string,
    request: SendConversationMessageRequestV1
  ): Promise<SendConversationMessageRequestV1>;
}

class ConversationResourceGroup implements IConversationResourceGroup {
  private Client: IArkeClient;
  constructor(client: IArkeClient) {
    this.Client = client;
  }

  public getBroadcastSettings(
    siteId: string
  ): Promise<BroadcastMessageSettingsResponseV1> {
    return this.Client.performGet<BroadcastMessageSettingsResponseV1>(
      `/v1/sites/${siteId}/broadcasts/settings`
    );
  }

  public getBroadcasts(
    siteId: string,
    page?: number,
    itemsPerPage?: number,
    filter?: string
  ): Promise<PaginatedItemsResponse<BroadcastResponseV1>> {
    return this.Client.performGet<PaginatedItemsResponse<BroadcastResponseV1>>(
      `/v1/sites/${siteId}/broadcasts?page=${page}&itemsPerPage=${itemsPerPage}${
        filter ? "&filter=" + filter : ""
      }`
    );
  }

  public getBroadcastMessage(
    siteId: string,
    broadcastId: string
  ): Promise<BroadcastResponseV1> {
    return this.Client.performGet<BroadcastResponseV1>(
      `/v1/sites/${siteId}/broadcasts/${broadcastId}`
    );
  }

  public sendBroadcast(
    siteId: string,
    request: SendBroadcastMessageRequestV1
  ): Promise<SendBroadcastMessageResponseV1> {
    return this.Client.performPost<SendBroadcastMessageResponseV1>(
      `/v1/sites/${siteId}/broadcasts`,
      request
    );
  }

  public sendBroadcastMessage(
    siteId: string,
    request: SendBroadcastMessageRequestV1
  ): Promise<SendBroadcastMessageResponseV1> {
    return this.Client.performPost<SendBroadcastMessageResponseV1>(
      `/v1/sites/${siteId}/broadcasts`,
      request
    );
  }

  public sendTestMessage(
    siteId: string,
    request: SendTestMessageRequestV1
  ): Promise<SendTestMessageResponseV1> {
    return this.Client.performPost<SendTestMessageResponseV1>(
      `/v1/sites/${siteId}/broadcasts/test`,
      request
    );
  }

  public getConversations(
    siteId: string,
    page?: number,
    itemsPerPage?: number,
    filter?: string,
    status?: string
  ): Promise<PaginatedItemsResponse<ConversationResponseV1>> {
    return this.Client.performGet<
      PaginatedItemsResponse<ConversationResponseV1>
    >(
      `/v1/sites/${siteId}/conversations?page=${page}&itemsPerPage=${itemsPerPage}${
        filter ? "&filter=" + filter : ""
      }${status ? "&status=" + status : ""}`
    );
  }

  public getConversation(
    siteId: string,
    conversationId: string
  ): Promise<ConversationResponseV1> {
    return this.Client.performGet<ConversationResponseV1>(
      `/v1/sites/${siteId}/conversations/${conversationId}`
    );
  }

  public updateConversationStatus(
    siteId: string,
    conversationId: string,
    request: StatusUpdateRequest
  ): Promise<void> {
    return this.Client.performPut<void>(
      `/v1/sites/${siteId}/conversations/${conversationId}`,
      request
    );
  }

  public createConversation(
    siteId: string,
    request: CreateConversationRequestV1
  ): Promise<CreateConversationResponseV1> {
    return this.Client.performPost<CreateConversationResponseV1>(
      `/v1/sites/${siteId}/conversations`,
      request
    );
  }

  public createBroadcastReply(
    siteId: string,
    broadcastId: string,
    request: SendConversationMessageRequestV1
  ): Promise<CreateConversationResponseV1> {
    return this.Client.performPost<CreateConversationResponseV1>(
      `/v1/sites/${siteId}/broadcasts/${broadcastId}/reply`,
      request
    );
  }

  public getConversationMessages(
    siteId: string,
    conversationId: string,
    page: number,
    numberOfMessages?: number
  ): Promise<PaginatedItemsResponse<ConversationMessageResponseV1>> {
    return this.Client.performGet<
      PaginatedItemsResponse<ConversationMessageResponseV1>
    >(
      `/v1/sites/${siteId}/conversations/${conversationId}/messages?page=${page}&numberOfMessages=${numberOfMessages}`
    );
  }

  public sendConversationMessage(
    siteId: string,
    conversationId: string,
    request: SendConversationMessageRequestV1
  ): Promise<SendConversationMessageRequestV1> {
    return this.Client.performPost<SendConversationMessageRequestV1>(
      `/v1/sites/${siteId}/conversations/${conversationId}/messages/`,
      request
    );
  }
}

export { ConversationResourceGroup, IConversationResourceGroup };
