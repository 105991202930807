import { IArkeClient } from "../Client/IArkeClient";
import { SaveDeviceRequestV1 } from "../Entities";

interface IDeviceResourceGroup {
  remove(deviceId: string): Promise<void>;
  save(deviceId: string, request: SaveDeviceRequestV1): Promise<void>;
}

class DeviceResourceGroup implements IDeviceResourceGroup {
  private Client: IArkeClient;
  constructor(client: IArkeClient) {
    this.Client = client;
  }

  public remove(deviceId: string): Promise<void> {
    return this.Client.performDelete(`/v1/devices/${deviceId}`);
  }

  public save(deviceId: string, request: SaveDeviceRequestV1): Promise<void> {
    return this.Client.performPut(`/v1/devices/${deviceId}`, request);
  }
}

export { IDeviceResourceGroup, DeviceResourceGroup };
