import { IArkeClient } from "../Client/IArkeClient";
import {
  CreateAccountRequestV1,
  AccountResponseV1
} from "../Entities/AccountEntities";

interface IAccountResourceGroup {
  create(request: CreateAccountRequestV1): Promise<string>;
  find(accountId: string): Promise<AccountResponseV1 | null>;
  list(): Promise<AccountResponseV1[]>;
}

class AccountResourceGroup implements IAccountResourceGroup {
  public list(): Promise<AccountResponseV1[]> {
    return this.Client.performGet(`/v1/accounts`);
  }
  public async create(request: CreateAccountRequestV1): Promise<string> {
    const result = await this.Client.performPost<{ accountId: string }>(
      "/v1/accounts",
      request
    );
    return result.accountId;
  }

  public async find(accountId: string): Promise<AccountResponseV1 | null> {
    return this.Client.performNullableGet<AccountResponseV1>(
      `/v1/accounts/${accountId}`
    );
  }
  private Client: IArkeClient;
  constructor(client: IArkeClient) {
    this.Client = client;
  }
}

export { IAccountResourceGroup, AccountResourceGroup };
