import {
  SiteStatisticsResponseV1,
  BillingStatus,
  PaginatedItemsResponse,
} from "../../Entities";
import { IArkeClient } from "../../Client/IArkeClient";
import {
  AddSiteRequestV1,
  UpdateSiteClientRequestV1,
  UpdateSiteSettingsRequestV1,
  UpdateSiteMessageTemplatesRequestV1,
  UpdateSiteNotificationSettingsRequestV1,
  UpdateSiteNet2HostProvisioningSettingsRequestV1,
  UpdateSiteAccessSettingsRequestV1,
} from "./SiteSettingRequests";
import { SiteResponseV1 } from "./SiteResponseV1";
import {
  SiteClientResponseV1,
  SiteSettingsResponseV1,
  SiteMessageTemplatesResponseV1,
  SiteNotificationSettingsResponseV1,
  SiteNet2HostProvisioningSettingsResponseV1,
  SiteAccessSettingsResponseV1,
  SiteNet2DoorV1,
} from "./SiteSettingsResponses";
import { FaqResponseV1 } from "./FaqResponseV1";
import { ParsedIdentityDocumentResponseV1 } from "./ParsedIdentityDocumentResponseV1";
import { LogoId } from "../../Helpers";
import {
  AdditionalFieldRequestV1,
  AdditionalFieldResponseV1,
  AddAdditionalFieldResponseV1,
  UpdateAdditionalFieldResponseV1,
  AdditionalFieldUserType,
  UpdateAdditionalFieldRequestV1,
} from "../../Entities/AdditionalFields";
import {
  UpdateSiteScreeningSettingsRequestV1,
  ScreeningSettingsResponseV1,
} from "../../Entities/Screening";

interface ISiteResourceGroup {
  add(accountId: string, request: AddSiteRequestV1): Promise<string>;
  decodeDocument(
    siteId: string,
    rawData: string
  ): Promise<ParsedIdentityDocumentResponseV1>;
  downloadInstaller(siteId: string): Promise<void>;
  list(): Promise<SiteResponseV1[]>;
  find(siteId: string): Promise<SiteResponseV1 | null>;
  findStatistics(siteId: string): Promise<SiteStatisticsResponseV1 | null>;
  sync(siteId: string): Promise<void>;
  findClient(siteId: string): Promise<SiteClientResponseV1 | null>;
  findSettings(siteId: string): Promise<SiteSettingsResponseV1 | null>;
  updateClientSettings(
    siteId: string,
    request: UpdateSiteClientRequestV1
  ): Promise<void>;
  updateSettings(
    siteId: string,
    request: UpdateSiteSettingsRequestV1
  ): Promise<void>;

  findMessageTemplates(
    siteId: string
  ): Promise<SiteMessageTemplatesResponseV1 | null>;
  updateMessageTemplates(
    siteId: string,
    request: UpdateSiteMessageTemplatesRequestV1
  ): Promise<void>;

  findNotificationSettings(
    siteId: string
  ): Promise<SiteNotificationSettingsResponseV1 | null>;
  updateNotificationSettings(
    siteId: string,
    request: UpdateSiteNotificationSettingsRequestV1
  ): Promise<void>;

  findNet2HostProvisioningSettings(
    siteId: string
  ): Promise<SiteNet2HostProvisioningSettingsResponseV1 | null>;
  updateNet2HostProvisioningSettings(
    siteId: string,
    request: UpdateSiteNet2HostProvisioningSettingsRequestV1
  ): Promise<void>;

  findAccessSettings(
    siteId: string
  ): Promise<SiteAccessSettingsResponseV1 | null>;
  updateAccessSettings(
    siteId: string,
    request: UpdateSiteAccessSettingsRequestV1
  ): Promise<void>;

  listNet2Doors(siteId: string): Promise<SiteNet2DoorV1[]>;
  listFaqItems(siteId: string): Promise<FaqResponseV1[]>;
  deleteLogo(siteId: string, logoId: LogoId): Promise<void>;
  getLogoSource(siteId: string, logoId: LogoId): string;
  updateLogo(siteId: string, logoId: LogoId, file: File): Promise<void>;

  requestPaid(siteId: string): Promise<void>;

  addAdditionalField(
    siteId: string,
    request: AdditionalFieldRequestV1
  ): Promise<AddAdditionalFieldResponseV1>;

  updateAdditionalField(
    siteId: string,
    request: UpdateAdditionalFieldRequestV1
  ): Promise<UpdateAdditionalFieldResponseV1>;

  deleteAdditionalField(
    siteId: string,
    additionalFieldId: string
  ): Promise<void>;

  getAdditionalFieldDetails(
    siteId: string,
    additionalFieldId: string
  ): Promise<AdditionalFieldResponseV1>;

  listAdditionalFields(
    siteId: string,
    userType: AdditionalFieldUserType,
    page: number,
    itemsPerPage: number
  ): Promise<PaginatedItemsResponse<AdditionalFieldResponseV1>>;

  updateScreeningSettings(
    siteId: string,
    request: UpdateSiteScreeningSettingsRequestV1
  ): Promise<void>;

  getScreeningSettings(siteId: string): Promise<ScreeningSettingsResponseV1>;
}

class SiteResourceGroup implements ISiteResourceGroup {
  private Client: IArkeClient;
  constructor(client: IArkeClient) {
    this.Client = client;
  }
  public requestPaid(siteId: string): Promise<void> {
    return this.Client.performPatch(`/v1/sites/${siteId}`, {
      billingStatus: BillingStatus.RequestedPaid,
    });
  }
  public deleteLogo(siteId: string, logoId: LogoId): Promise<void> {
    return this.Client.performDelete(`/v1/sites/${siteId}/settings/${logoId}`);
  }
  public getLogoSource(siteId: string, logoId: LogoId): string {
    return `${this.Client.baseUrl}/v1/sites/${siteId}/${logoId}.png`;
  }
  public async updateLogo(
    siteId: string,
    logoId: LogoId,
    file: File
  ): Promise<void> {
    const formData = new FormData();
    formData.append("Logo", file);
    await fetch(
      `${this.Client.baseUrl}/v1/sites/${siteId}/settings/${logoId}`,
      {
        method: "PUT",
        body: formData,
        headers: { Authorization: `Bearer ${this.Client.getAccessToken()}` },
      }
    );
  }
  public decodeDocument(
    siteId: string,
    rawData: string
  ): Promise<ParsedIdentityDocumentResponseV1> {
    return this.Client.performPost(`/v1/sites/${siteId}/documents/decode`, {
      rawData: rawData,
    });
  }
  public listFaqItems(siteId: string): Promise<FaqResponseV1[]> {
    return this.Client.performGet(`/v1/sites/${siteId}/faq`);
  }
  public listNet2Doors(siteId: string): Promise<SiteNet2DoorV1[]> {
    return this.Client.performGet(`/v1/sites/${siteId}/net2/doors`);
  }
  public updateClientSettings(
    siteId: string,
    request: UpdateSiteClientRequestV1
  ): Promise<void> {
    return this.Client.performPatch(`/v1/sites/${siteId}/client`, request);
  }
  public async add(
    accountId: string,
    request: AddSiteRequestV1
  ): Promise<string> {
    const result = await this.Client.performPost<{ siteId: string }>(
      `/v1/accounts/${accountId}/sites`,
      request
    );
    return result.siteId;
  }
  public downloadInstaller(siteId: string): Promise<void> {
    return this.Client.performGet(`/v1/sites/${siteId}/installers/net2`);
  }

  public sync(siteId: string): Promise<void> {
    return this.Client.performPost(`/v1/sites/${siteId}/sync`, {});
  }

  public list(): Promise<SiteResponseV1[]> {
    return this.Client.performGet<SiteResponseV1[]>("/v1/sites");
  }

  public async find(siteId: string): Promise<SiteResponseV1 | null> {
    return this.Client.performNullableGet<SiteResponseV1>(
      `/v1/sites/${siteId}`
    );
  }

  public async findStatistics(
    siteId: string
  ): Promise<SiteStatisticsResponseV1 | null> {
    return this.Client.performNullableGet<SiteStatisticsResponseV1>(
      `/v1/sites/${siteId}/statistics`
    );
  }

  public async findSettings(
    siteId: string
  ): Promise<SiteSettingsResponseV1 | null> {
    return this.Client.performNullableGet<SiteSettingsResponseV1>(
      `/v1/sites/${siteId}/settings`
    );
  }

  public updateSettings(
    siteId: string,
    request: UpdateSiteSettingsRequestV1
  ): Promise<void> {
    return this.Client.performPut(`/v1/sites/${siteId}/settings`, request);
  }

  public async findClient(
    siteId: string
  ): Promise<SiteClientResponseV1 | null> {
    return this.Client.performNullableGet<SiteClientResponseV1>(
      `/v1/sites/${siteId}/client`
    );
  }

  public async findMessageTemplates(
    siteId: string
  ): Promise<SiteMessageTemplatesResponseV1 | null> {
    return this.Client.performNullableGet<SiteMessageTemplatesResponseV1>(
      `/v1/sites/${siteId}/settings/messages/templates`
    );
  }

  public updateMessageTemplates(
    siteId: string,
    request: UpdateSiteMessageTemplatesRequestV1
  ): Promise<void> {
    return this.Client.performPut(
      `/v1/sites/${siteId}/settings/messages/templates`,
      request
    );
  }

  public async findNotificationSettings(
    siteId: string
  ): Promise<SiteNotificationSettingsResponseV1 | null> {
    return this.Client.performNullableGet<SiteNotificationSettingsResponseV1>(
      `/v1/sites/${siteId}/settings/notifications`
    );
  }

  public updateNotificationSettings(
    siteId: string,
    request: UpdateSiteNotificationSettingsRequestV1
  ): Promise<void> {
    return this.Client.performPut(
      `/v1/sites/${siteId}/settings/notifications`,
      request
    );
  }

  public async findNet2HostProvisioningSettings(
    siteId: string
  ): Promise<SiteNet2HostProvisioningSettingsResponseV1 | null> {
    return this.Client.performNullableGet<
      SiteNet2HostProvisioningSettingsResponseV1
    >(`/v1/sites/${siteId}/settings/net2/hostprovisioning`);
  }

  public updateNet2HostProvisioningSettings(
    siteId: string,
    request: UpdateSiteNet2HostProvisioningSettingsRequestV1
  ): Promise<void> {
    return this.Client.performPut(
      `/v1/sites/${siteId}/settings/net2/hostprovisioning`,
      request
    );
  }

  public async findAccessSettings(
    siteId: string
  ): Promise<SiteAccessSettingsResponseV1 | null> {
    return this.Client.performNullableGet<SiteAccessSettingsResponseV1>(
      `/v1/sites/${siteId}/settings/access`
    );
  }

  public updateAccessSettings(
    siteId: string,
    request: UpdateSiteAccessSettingsRequestV1
  ): Promise<void> {
    return this.Client.performPut(
      `/v1/sites/${siteId}/settings/access`,
      request
    );
  }

  public async addAdditionalField(
    siteId: string,
    request: AdditionalFieldRequestV1
  ): Promise<AddAdditionalFieldResponseV1> {
    return this.Client.performPost<AddAdditionalFieldResponseV1>(
      `/v1/sites/${siteId}/settings/additionalfields`,
      request
    );
  }

  public async updateAdditionalField(
    siteId: string,
    request: UpdateAdditionalFieldRequestV1
  ): Promise<UpdateAdditionalFieldResponseV1> {
    return this.Client.performPut<UpdateAdditionalFieldResponseV1>(
      `/v1/sites/${siteId}/settings/additionalfields`,
      request
    );
  }

  public async deleteAdditionalField(
    siteId: string,
    additionalFieldId: string
  ): Promise<void> {
    return this.Client.performDelete(
      `/v1/sites/${siteId}/settings/additionalfields/${additionalFieldId}`
    );
  }

  public async getAdditionalFieldDetails(
    siteId: string,
    additionalFieldId: string
  ) {
    return this.Client.performGet<Promise<AdditionalFieldResponseV1>>(
      `/v1/sites/${siteId}/settings/additionalfields/${additionalFieldId}`
    );
  }

  public async listAdditionalFields(
    siteId: string,
    userType: AdditionalFieldUserType,
    page: number,
    itemsPerPage: number
  ): Promise<PaginatedItemsResponse<AdditionalFieldResponseV1>> {
    return this.Client.performGet<
      PaginatedItemsResponse<AdditionalFieldResponseV1>
    >(
      `/v1/sites/${siteId}/settings/additionalfields/?type=${userType}&page=${page}&itemsPerPage=${itemsPerPage}`
    );
  }

  public async updateScreeningSettings(
    siteId: string,
    request: UpdateSiteScreeningSettingsRequestV1
  ): Promise<void> {
    return this.Client.performPut(
      `/v1/sites/${siteId}/settings/screening`,
      request
    );
  }

  public async getScreeningSettings(
    siteId: string
  ): Promise<ScreeningSettingsResponseV1> {
    return this.Client.performGet<ScreeningSettingsResponseV1>(
      `/v1/sites/${siteId}/settings/screening`
    );
  }
}

export { ISiteResourceGroup, SiteResourceGroup };
