export enum AdditionalFieldUserType {
  Visitor = "Visitor",
  User = "User",
}

export enum AdditionalFieldType {
  YesNo = "YesNo",
  Text = "Text",
  MultipleChoice = "MultipleChoice",
  Date = "Date",
}

export class AdditionalFieldRequestV1 {
  public name!: string;
  public userType!: AdditionalFieldUserType;
  public fieldType!: AdditionalFieldType;
  public required!: boolean;
  public defaultValue!: string;
  public description!: string;
  public fieldOptions!: string[];
}

export class UpdateAdditionalFieldRequestV1 {
  public additionalFieldId!: string;
  public name!: string;
  public userType!: AdditionalFieldUserType;
  public fieldType!: AdditionalFieldType;
  public required!: boolean;
  public defaultValue!: string;
  public description!: string;
  public fieldOptions!: string[];
}

export interface AdditionalFieldResponseV1 {
  additionalFieldId: string;
  name: string;
  userType: AdditionalFieldUserType;
  fieldType: AdditionalFieldType;
  required: boolean;
  defaultValue: string;
  description: string;
  fieldOptions: string[];
}

export interface AddAdditionalFieldResponseV1 {
  additionalFieldId: string;
}

export interface UpdateAdditionalFieldResponseV1 {
  additionalFieldId: string;
}
