import { IArkeClient } from "../Client/IArkeClient";
import { WebErrorResponse } from "simplerestclients";
import {
  CountryResponseV1,
  CultureResponseV1,
  TimeZoneResponseV1,
  RecommendArkeRequestV1,
  RecommendArkeResponse,
} from "../Entities/SystemEntities";
import { ErrorResponse } from "../Entities";

interface ISystemResourceGroup {
  listTimezones(): Promise<TimeZoneResponseV1[]>;
  listCultures(): Promise<CultureResponseV1[]>;
  listCountries(): Promise<CountryResponseV1[]>;
  recommendToContact(
    contactToRecommend: RecommendArkeRequestV1
  ): Promise<RecommendArkeResponse | ErrorResponse>;

  getQRUrl(content: string, size?: number): string;
}

class SystemResourceGroup implements ISystemResourceGroup {
  private Client: IArkeClient;
  constructor(client: IArkeClient) {
    this.Client = client;
  }

  public listCountries(): Promise<CountryResponseV1[]> {
    return this.Client.performGet<CountryResponseV1[]>(`/v1/system/countries`);
  }
  public listTimezones(): Promise<TimeZoneResponseV1[]> {
    return this.Client.performGet<TimeZoneResponseV1[]>(`/v1/system/timezones`);
  }
  public listCultures(): Promise<CultureResponseV1[]> {
    return this.Client.performGet<TimeZoneResponseV1[]>(`/v1/system/cultures`);
  }

  public async recommendToContact(
    recommendRequest: RecommendArkeRequestV1
  ): Promise<RecommendArkeResponse | ErrorResponse> {
    try {
      const response = await this.Client.performPost<RecommendArkeResponse>(
        `/v1/system/recommend`,
        recommendRequest
      );
      return response;
    } catch (e) {
      const error: WebErrorResponse = e;
      if (error.statusCode === 400) {
        return error.body as ErrorResponse;
      }
      throw e;
    }
  }

  public getQRUrl(content: string, size?: number): string {
    return `${this.Client.baseUrl}/v1/system/qr?content=${content}${
      size ? "&size=" + size : ""
    }`;
  }
}

export { ISystemResourceGroup, SystemResourceGroup };
