import { IArkeClient } from "../../Client/IArkeClient";
import {
  VisitResponseV1,
  FullVisitResponse,
  PaginatedItemsResponse,
  ErrorResponse,
  WatchlistType,
  UpdateWatchlistRequestV1,
  UpdateWatchlistResponse,
} from "../../Entities";
import { WebErrorResponse } from "simplerestclients";
import { AddVisitRequest } from "./AddVisitRequest";
import { AddVisitResult } from "./AddVisitResponse";
import { IVisitResourceGroup } from "./IVisitResourceGroup";
import { VisitDetailsResponseV1 } from "./VisitDetailsResponseV1";
import { StatusUpdateResponseV1 } from "./StatusUpdateResponseV1";
import {
  AddToWatchlistResponse,
  WatchlistRequestV1,
  WatchlistResponseV1,
} from "../../Entities";

class VisitResourceGroup implements IVisitResourceGroup {
  private Client: IArkeClient;
  constructor(client: IArkeClient) {
    this.Client = client;
  }

  public async updateWatchlist(
    siteId: string,
    request: UpdateWatchlistRequestV1
  ): Promise<UpdateWatchlistResponse> {
    return this.Client.performPut<UpdateWatchlistResponse>(
      `/v1/sites/${siteId}/visits/watchlist`,
      request
    );
  }

  public async addToWatchlist(
    siteId: string,
    request: WatchlistRequestV1
  ): Promise<AddToWatchlistResponse> {
    return this.Client.performPost<AddToWatchlistResponse>(
      `/v1/sites/${siteId}/visits/watchlist`,
      request
    );
  }

  public async listWatchlist(
    siteId: string,
    page: number,
    itemsPerPage: number,
    filter?: string,
    type?: WatchlistType | "All"
  ): Promise<PaginatedItemsResponse<WatchlistResponseV1>> {
    return this.Client.performGet<PaginatedItemsResponse<WatchlistResponseV1>>(
      `/v1/sites/${siteId}/visits/watchlist?page=${page}&itemsPerPage=${itemsPerPage}${
        filter ? "&filter=" + filter : ""
      }${type ? "&type=" + type.toString() : ""}`
    );
  }

  public watchlistEntryDetails(
    siteId: string,
    watchlistId: string
  ): Promise<WatchlistResponseV1 | null> {
    return this.Client.performNullableGet<WatchlistResponseV1 | null>(
      `/v1/sites/${siteId}/visits/watchlist/${watchlistId}`
    );
  }

  public watchlistEntryDelete(
    siteId: string,
    watchlistId: string
  ): Promise<WatchlistResponseV1 | null> {
    return this.Client.performDelete(
      `/v1/sites/${siteId}/visits/watchlist/${watchlistId}`
    );
  }

  public listWatchlistEntryEvents(
    siteId: string,
    watchlistId: string,
    page: number,
    itemsPerPage: number
  ): Promise<PaginatedItemsResponse<FullVisitResponse>> {
    let url = `/v1/sites/${siteId}/visits/watchlist/${watchlistId}/events?page=${page}&itemsPerPage=${itemsPerPage}`;
    return this.Client.performGet<PaginatedItemsResponse<FullVisitResponse>>(
      url
    );
  }

  public export(
    siteId: string,
    from: string,
    to: string
  ): Promise<{ exportId: string }> {
    return this.Client.performPost<{ exportId: string }>(
      `/v1/sites/${siteId}/visits/exports?from=${from}&to=${to}`,
      null
    );
  }
  public async find(
    siteId: string,
    visitId: string
  ): Promise<VisitResponseV1 | null> {
    return this.Client.performNullableGet<VisitResponseV1>(
      `/v1/sites/${siteId}/visits/${visitId}`
    );
  }
  public async findByPin(
    siteId: string,
    pin: string
  ): Promise<VisitResponseV1 | null> {
    return this.Client.performNullableGet<VisitResponseV1>(
      `/v1/sites/${siteId}/visits/pins/${pin}`
    );
  }
  public async findDetails(
    siteId: string,
    visitId: string
  ): Promise<VisitDetailsResponseV1 | null> {
    return this.Client.performNullableGet<VisitDetailsResponseV1>(
      `/v1/sites/${siteId}/visits/${visitId}/details`
    );
  }

  public list(
    siteId: string,
    page: number,
    itemsPerPage: number,
    include: "Me" | "All",
    date?: string
  ): Promise<PaginatedItemsResponse<VisitResponseV1>> {
    let url = `/v1/sites/${siteId}/visits?page=${page}&itemsPerPage=${itemsPerPage}&include=${include}`;
    if (date !== undefined) {
      url += `&date=${date}`;
    }
    return this.Client.performGet<PaginatedItemsResponse<VisitResponseV1>>(url);
  }

  public fullVisitList(
    siteId: string,
    page: number,
    itemsPerPage: number,
    include: "Me" | "All",
    date?: string
  ): Promise<PaginatedItemsResponse<FullVisitResponse>> {
    let url = `/v1/full/sites/${siteId}/visits?page=${page}&itemsPerPage=${itemsPerPage}&include=${include}`;
    if (date !== undefined) {
      url += `&date=${date}`;
    }
    return this.Client.performGet<PaginatedItemsResponse<FullVisitResponse>>(
      url
    );
  }

  public listForHost(
    siteId: string,
    hostId: string,
    page: number,
    itemsPerPage: number
  ): Promise<PaginatedItemsResponse<VisitResponseV1>> {
    let url = `/v1/sites/${siteId}/hosts/${hostId}/visits?page=${page}&itemsPerPage=${itemsPerPage}`;
    return this.Client.performGet<PaginatedItemsResponse<VisitResponseV1>>(url);
  }

  public async add(
    siteId: string,
    request: AddVisitRequest
  ): Promise<ErrorResponse | AddVisitResult[]> {
    try {
      const response = await this.Client.performPost<AddVisitResult[]>(
        `/v1/sites/${siteId}/visits`,
        request
      );
      return response;
    } catch (e) {
      const error: WebErrorResponse = e;
      if (error.statusCode === 400) {
        return error.body as ErrorResponse;
      }
      throw e;
    }
  }

  public overrideDailyLimit(siteId: string, visitId: string): Promise<void> {
    return this.Client.performPost(
      `/v1/sites/${siteId}/visits/${visitId}/overridedaily`,
      {}
    );
  }
  public cancel(siteId: string, visitId: string): Promise<void> {
    return this.Client.performPost(
      `/v1/sites/${siteId}/visits/${visitId}/cancel`,
      {}
    );
  }
}

export {
  AddVisitRequest,
  VisitResourceGroup,
  AddVisitResult,
  IVisitResourceGroup,
  StatusUpdateResponseV1,
  VisitDetailsResponseV1,
};
