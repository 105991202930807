import { WebErrorResponse } from "simplerestclients";
import { IArkeClient } from "../../Client/IArkeClient";
import {
  ErrorResponse,
  UpdateScreeningQuestionRequestV1,
} from "../../Entities";
import {
  PublicUpdateVisitRequestV1 as VisitorVisitUpdateRequestV1,
  PublicVisitResponseV1 as VisitorVisitResponseV1,
  VisitorScreeningResponseV1,
} from "../../Entities";

export interface IVisitorVisitsResourceGroup {
  getVisit(
    siteId: string,
    visitId: string
  ): Promise<VisitorVisitResponseV1 | ErrorResponse>;
  getScreening(
    siteId: string,
    visitId: string
  ): Promise<VisitorScreeningResponseV1 | void>;
  updateVisit(
    siteId: string,
    visitId: string,
    request: VisitorVisitUpdateRequestV1
  ): Promise<void | ErrorResponse>;

  setAnswer(
    siteId: string,
    visitId: string,
    request: UpdateScreeningQuestionRequestV1
  ): Promise<void>;
}

export class VisitorVisitsResourceGroup implements IVisitorVisitsResourceGroup {
  private Client: IArkeClient;
  constructor(client: IArkeClient) {
    this.Client = client;
  }
  setAnswer(
    siteId: string,
    visitId: string,
    request: UpdateScreeningQuestionRequestV1
  ): Promise<void> {
    return this.Client.performPost<void>(
      `/v1/visitor/sites/${siteId}/visits/${visitId}/screening/answers`,
      request
    );
  }
  getScreening(
    siteId: string,
    visitId: string
  ): Promise<void | VisitorScreeningResponseV1> {
    return this.Client.performGet(
      `/v1/visitor/sites/${siteId}/visits/${visitId}/screening`
    );
  }
  public async updateVisit(
    siteId: string,
    visitId: string,
    request: VisitorVisitUpdateRequestV1
  ): Promise<void | ErrorResponse> {
    try {
      return this.Client.performPut(
        `/v1/visitor/sites/${siteId}/visits/${visitId}`,
        request
      );
    } catch (e) {
      const error: WebErrorResponse = e;
      if (error.statusCode === 400) {
        return error.body as ErrorResponse;
      }
      throw e;
    }
  }

  public async getVisit(
    siteId: string,
    visitId: string
  ): Promise<VisitorVisitResponseV1 | ErrorResponse> {
    try {
      return this.Client.performGet<VisitorVisitResponseV1>(
        `/v1/visitor/sites/${siteId}/visits/${visitId}`
      );
    } catch (e) {
      const error: WebErrorResponse = e;
      if (error.statusCode === 400) {
        return error.body as ErrorResponse;
      }
      throw e;
    }
  }
}
