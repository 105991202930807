export enum VisitStatus {
  Unknown = 0,
  Requested = 5,
  Scheduled = 6,
  AwaitingScreening = 7,
  Invited = 10,
  Arrived = 20,
  Departed = 30,
  Expired = 100,
  Finished = 150,
  AccountDisabled = 410,
  InvalidInput = 420,
  ErrorCreating = 430,
  ExceededCount = 440,
  CallFailed = 450,
  Cancelled = 460,
  Denied = 470,
  ScreeningFailed = 480,
}
