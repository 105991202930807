import { IArkeClient } from "../Client/IArkeClient";
import { WebErrorResponse } from "simplerestclients";

interface IClientResourceGroup {
  findLatestInstallerVersion(client: string): Promise<string | null>;
}

class ClientResourceGroup implements IClientResourceGroup {
  private Client: IArkeClient;
  constructor(client: IArkeClient) {
    this.Client = client;
  }
  public async findLatestInstallerVersion(
    client: string
  ): Promise<string | null> {
    return this.Client.performNullableGet<string>(
      `/v1/clients/installers/${client}/latest/version`
    );
  }
}

export { IClientResourceGroup, ClientResourceGroup };
