export enum RecommendationType {
  share = "share",
  recommend = "recommend",
}

export interface TimeZoneResponseV1 {
  id: string;
  name: string;
}

export interface CultureResponseV1 {
  id: string;
  name: string;
}

export interface CountryResponseV1 {
  name: string;
  alpha2: string;
  alpha3: string;
  numericCode: number;
  dialCodes: string[];
}

export class ContactToRecommend {
  public phoneNumber?: string;
  public firstName?: string;
  public lastName?: string;
  public email?: string;
}

export interface RecommendArkeResponse {
  recommendationActionSuccess: boolean;
}
export interface RecommendArkeRequestV1 {
  contactToRecommend: ContactToRecommend;
  recommendationType: RecommendationType;
}
