import { IArkeClient } from "../Client/IArkeClient";
import { ErrorResponse } from "../Entities";

interface ISupportRequestResourceGroup {
  create(message: string, extraData?: string): Promise<void>;
  createForSite(
    siteId: string,
    message: string,
    extraData?: string
  ): Promise<void>;
}

class SupportRequestResourceGroup implements ISupportRequestResourceGroup {
  private Client: IArkeClient;
  constructor(client: IArkeClient) {
    this.Client = client;
  }

  public async create(message: string, extraData?: string) {
    const response = await this.Client.performPost<ErrorResponse | null>(
      "/v1/support/tickets",
      {
        message: message,
        extraData: extraData === undefined ? "" : extraData
      }
    );
    if (response == null) {
      return;
    } else {
      console.log(response.errorMessage);
    }
  }
  public async createForSite(
    siteId: string,
    message: string,
    extraData?: string
  ) {
    const response = await this.Client.performPost<ErrorResponse | null>(
      `/v1/sites/${siteId}/support/tickets`,
      {
        message: message,
        extraData: extraData === undefined ? "" : extraData
      }
    );
    if (response == null) {
      return;
    } else {
      console.log(response.errorMessage);
    }
  }
}

export { ISupportRequestResourceGroup, SupportRequestResourceGroup };
