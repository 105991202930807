﻿export enum ConversationStatus {
  Open,
  Closed,
  All,
}

export class StatusUpdateRequest {
  status!: ConversationStatus;
}

//Broadcast
export class SendBroadcastMessageRequestV1 {
  public subject!: string;
  public markdownContent!: string;
  public to!: string;
}

export interface BroadcastMessageSettingsResponseV1 {
  hostCount: number;
  identityUserCount: number;
}

export interface SendBroadcastMessageResponseV1 {
  broadcastMessageId: string;
}

//Test Message
export class SendTestMessageRequestV1 {
  public subject!: string;
  public markdownContent!: string;
  public sendToEmail!: string[];
}

export interface SendTestMessageResponseV1 {
  testMessageId: string;
}

//Conversations
export interface ConversationResponseV1 {
  conversationId: string;
  initiator: string;
  participants: ConversationParticipantResponseV1;
  subject: string;
  status: ConversationStatus;
  sentAt: string;
  updatedAt: string;
}

export interface ConversationParticipantResponseV1 {
  [key: string]: string;
}

export interface ConversationMessageResponseV1 {
  messageId: string;
  from: string;
  onBehalfOf?: string;
  markdownContent: string;
  sentAt: string;
}

export class CreateConversationRequestV1 {
  public participants!: string[];
  public subject!: string;
  public message!: SendConversationMessageRequestV1;
}

export interface CreateConversationResponseV1 {
  conversationId: string;
}

export class SendConversationMessageRequestV1 {
  public markdownContent!: string;
}

//Broadcasts
export interface BroadcastResponseV1 {
  broadcastId: string;
  to: string;
  subject: string;
  markdownContent: string;
  sentAt: string;
}
