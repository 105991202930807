import { IArkeClient } from "../../Client/IArkeClient";
import { VisitDocumentResponseV1 } from "./VisitDocumentResponseV1";
import { EnrichVisitWithDocumentsRequestV1 } from "./EnrichVisitWithDocumentsRequestV1";

interface IVisitDocumentResourceGroup {
  list(siteId: string, visitId: string): Promise<VisitDocumentResponseV1[]>;
  enrichVisit(
    siteId: string,
    visitId: string,
    documents: EnrichVisitWithDocumentsRequestV1
  ): Promise<void>;
}

class VisitDocumentResourceGroup implements IVisitDocumentResourceGroup {
  private Client: IArkeClient;
  constructor(client: IArkeClient) {
    this.Client = client;
  }
  public list(
    siteId: string,
    visitId: string
  ): Promise<VisitDocumentResponseV1[]> {
    return this.Client.performGet(
      `/v1/sites/${siteId}/visits/${visitId}/documents`
    );
  }
  public enrichVisit(
    siteId: string,
    visitId: string,
    documents: EnrichVisitWithDocumentsRequestV1
  ): Promise<void> {
    return this.Client.performPost(
      `/v1/sites/${siteId}/visits/${visitId}/documents`,
      documents
    );
  }
}

export { VisitDocumentResourceGroup, IVisitDocumentResourceGroup };
